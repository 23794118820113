<template>
  <b-container fluid>
      <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Berechtigungen</h4>
                            <p>Liste aller Berechtigungen</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-default mr-1" @click="loadVariationGroups">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('variations.create')">
                                <i class="fas fa-plus-circle"></i> Neue Variantengruppe
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="variationGroups" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <router-link class="btn btn-success btn-sm mr-1" :to="{name: 'VariationGroup.show', params: {id: row.item.id}}" v-if="$auth.check('variations.show')"><i class="fas fa-eye"></i></router-link>
                                        <router-link class="btn btn-warning btn-sm mr-1" :to="{name: 'VariationGroup.details', params: {id: row.item.id}}" v-if="$auth.check('variations.edit')"><i class="fas fa-edit"></i></router-link>
                                        <b-button size="sm" @click="deleteVariationGroup(row.item.id)" variant="danger" v-if="$auth.check('variations.destroy')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="VariationGroupModal" title="Neue Variation erstellen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Name</label>
                            <div class="col-sm-9">
                                <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" tabindex="1"/>
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                            <div class="col-sm-9">
                                <input v-model="form.description" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('description')}" tabindex="2"/>
                                <has-error :form="form" field="description"></has-error>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </b-modal>
  </b-container>
</template>

<script>
export default {
    name: "VariationGroupsIndex",
    title: "Artikelvarianten",

    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                description: "",
            }),
            variationGroups: [],
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "description", label: "Beschreibung", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
        }
    },

    methods: {
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createVariationGroup();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("VariationGroupModal");
        },

        createVariationGroup() {
            this.$Progress.start();
            this.form
                .post("/variations")
                .then(() => {
                    this.$bvModal.hide("VariationGroupModal");
                    this.$swal({
                        icon: "success",
                        title: "Variation wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadVariationGroups();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteVariationGroup(id){
            this.$swal({
                title: "Möchtest du die Variantengruppe wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/variations/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Variantengruppe erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadVariationGroups();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },


        async loadVariationGroups()
        {
            this.$Progress.start();
            await this.axios
                .get('/variations')
                .then((response) => {
                    this.variationGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadVariationGroups();
    },

    computed: {
        tableData() {
            return this.variationGroups || [];
        },

        rows() {
            return this.variationGroups.length;
        },
    },

}
</script>

<style>

</style>